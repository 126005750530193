import { useEffect, useState } from "react";
import ModalWindow from "../../../../Elements/ModalWindow/ModalWindow";
import { GetRolesForGroup } from "../../../../../Scripts/Requests/InGameRolesRequests";
import { AddPlayerRole, EditPlayerRole } from "../../../../../Scripts/Requests/PlayerInGameRolesRequest";

const AddEditPlayerInGameRole = ({edit, closeFunction, defaultData, onSuccess, groupId}) => {
    //const [refresh, setRefresh] = useState("");
    
    const [gameId, setGameId] = useState(edit ? defaultData.userId : "");
    const [discordId, setDiscordId] = useState(edit ? defaultData.discordId  : "");
    const [roleId, setRoleId] = useState(edit ? defaultData.inGameRoleId : 1);
    const [expiresDate, setExpiresDate] = useState(edit ? defaultData.expires : "");
    const [shouldExpire, SetShouldExpire] = useState(edit ? defaultData.shouldExpire : false);

    const [roles, setRoles] = useState([]);

    useEffect(() => {
        GetRolesForGroup(groupId, setRoles);
    }, []);

    const AddNewPlayerRole = () => {

        let data = {
            UserId : gameId,
            DiscordId : discordId,
            InGameRoleId : roleId,
            Expires : expiresDate,
            ShouldExpire : shouldExpire,
            ServerGroupId: groupId
        }

        if(edit){
            data.id = defaultData.id;
        }

        if(edit)
        {
            data.id = defaultData.id;
            console.log(data);
            EditPlayerRole(data, () => { onSuccess() });
        }    
        else
            AddPlayerRole(data, () => { onSuccess() });
    }

    return (
        <ModalWindow
            title={ edit ? "Edit" : "Add" }
            showCloseButton={true}
            closeFunction={closeFunction}
            width={"45%"}
            content={
            <>
                {/* {edit && 
                    <>
                        <label>Id</label>
                        <input style={{margin:"0px 5px 30px", width:"calc(100% - 10px)"}} type="text" className="inputStyle"></input>
                    </>
                } */}
                <label>Game Id</label>
                <input onChange={(e) => setGameId(e.target.value)} value={gameId} type="text" className="inputStyle modalInput"></input>

                <label>Discord Id</label>
                <input onChange={(e) => setDiscordId(e.target.value)} value={discordId} type="text" className="modalInput inputStyle"></input>

                <label>Role</label>
                {/* <input onChange={(e) => setRole(e.target.value)} value={role} type="text" className="modalInput inputStyle"></input> */}
                <select onChange={(e) => {console.log(e.target.value); setRoleId(e.target.value);} } defaultValue={roleId} className="modalInput" style={{background: "#2a2a36"}}>
                    <option></option>
                    { roles.map(role => 
                        <option selected={role.id == roleId} value={role.id} key={`role_${role.id}`}>{role.roleName}</option>
                    )}
                </select>

                <div style={{marginBottom: "25px"}}>
                    <label>Should Expire</label>
                    <input onChange={(e) => SetShouldExpire(e.target.checked)} defaultChecked={shouldExpire} style={{marginLeft:"10px"}} type="checkbox" ></input>
                </div>
                
                <label>Expires Date</label>
                <input onChange={(e) => setExpiresDate(e.target.value) } defaultValue={expiresDate.split("T")[0]}  type="date" className="modalInput inputStyle" style={{background: "#2a2a36"}}></input>
                
                <button onClick={() => AddNewPlayerRole()} style={{margin:"20px 5px 20px", width:"calc(100% - 10px)"}}>{edit ? "Edit" : "Add"}</button>
            </>}
        />
    )
};

export default AddEditPlayerInGameRole;