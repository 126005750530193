import { RemoveRole } from "../../../../../Scripts/Requests/InGameRolesRequests";
import { RemovePlayerRole } from "../../../../../Scripts/Requests/PlayerInGameRolesRequest";
import ModalWindow from "../../../../Elements/ModalWindow/ModalWindow";

const RemoveInGameRoleModal = ({role, closeFunction, onSuccess}) => {
    const RemoveRoleClick = () => {
        RemoveRole(role.id, onSuccess)
    }

    return (
        <ModalWindow 
            title={ "Remove" }
            showCloseButton={true}
            closeFunction={closeFunction}
            width={"45%"}
            content={
            <>
                <p>Do you really want to remove this role?</p>
                <button onClick={() => RemoveRoleClick()} style={{margin:"20px 5px 20px", width:"calc(100% - 10px)"}}>Remove</button>
                </>}
            />
    );
}

export default RemoveInGameRoleModal;