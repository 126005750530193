import { useState } from "react";
import ModalWindow from "../../../../Elements/ModalWindow/ModalWindow";
import { AddRole, EditRole } from "../../../../../Scripts/Requests/InGameRolesRequests";

const AddEditGroupRoleModal = ({edit, closeFunction, defaultData, onSuccess, groupId}) => {

    // const [gameId, setGameId] = useState("");
    const [discordId, setDiscordId] = useState(edit ? defaultData.discordRoleId : "");
    const [role, setRole] = useState(edit ? defaultData.roleName : "");
    const [priority, setPriority] = useState(edit ? defaultData.priority : 0);
    // const [expiresDate, setExpiresDate] = useState(new Date());
    // const [shouldExpire, SetShouldExpire] = useState(false);

    const AddNewRole = () => {

        let data = {
            discordRoleId : discordId,
            RoleName : role,
            Priority : priority,
            // Expires : expiresDate,
            // ShouldExpire : shouldExpire,
            ServerGroupId: groupId
        }

        if(edit)
        {
            data.id = defaultData.id;
            EditRole(data, onSuccess);
        }
        else
            AddRole(data, onSuccess);
    }

    return (
        <ModalWindow
            title={ edit ? "Edit" : "Add" }
            showCloseButton={true}
            closeFunction={closeFunction}
            width={"45%"}
            content={
            <>
                <label>Role</label>
                <input onChange={(e) => setRole(e.target.value)} value={role} type="text" className="modalInput inputStyle"></input>

                <label>Role Discord Id</label>
                <input onChange={(e) => setDiscordId(e.target.value)} value={discordId} type="text" className="modalInput inputStyle"></input>

                <label>Priority</label>
                <input onChange={(e) => setPriority(e.target.value)} value={priority} type="text" className="modalInput inputStyle"></input>

                <button onClick={() => AddNewRole()} style={{margin:"20px 5px 20px", width:"calc(100% - 10px)"}}>{edit ? "Edit" : "Add"}</button>
            </>}
        />
    )
}

export default AddEditGroupRoleModal;