const PredefinedReasonData =
[
    { Reason: "Utrudnianie pracy moderacji (4.4)", Time: "1d" },
    { Reason: "Wyjscie podczas przesluchania (4.4)", Time: "50y" },
    { Reason: "Teamkill (2.1)", Time: "1d" },
    { Reason: "Teamkill (2.1)", Time: "3d" },
    { Reason: "Teamkill (2.1)", Time: "7d" },
    { Reason: "Masowy teamkill (2.1)", Time: "30d" },
    { Reason: "Raid (4.5)", Time: "50y" },
    { Reason: "Cheating (2.2)", Time: "50y" },
    { Reason: "Toksyczność (1.1)", Time: "1d" },
    { Reason: "Toksyczność (1.1)", Time: "3d" },
    { Reason: "Toksyczność (1.1)", Time: "7d" },
    { Reason: "Mocna Toksyczność (1.1)", Time: "7d" },
    { Reason: "Mocna Toksyczność (1.1)", Time: "14d" },
    { Reason: "Mocna Toksyczność (1.1)", Time: "30d" },
    { Reason: "Mocna Toksyczność (1.1)", Time: "100d" },
    { Reason: "Ksenofobia (1.2)", Time: "50y" },
    { Reason: "Korzystanie z zewnetrznego komunikatora (1.5)", Time: "0" },
    { Reason: "Korzystanie z zewnetrznego komunikatora (1.5)", Time: "1d" },
    { Reason: "Poslugiwanie sie jezykiem niedozwolonym (1.6)", Time: "0" },
    { Reason: "Poslugiwanie sie jezykiem niedozwolonym (1.6)", Time: "14d" },
    { Reason: "Wykorzystywanie blędow gry (2.2)", Time: "0" },
    { Reason: "Wykorzystywanie blędow gry (2.2)", Time: "1d" },
    { Reason: "Unikanie gry jako SCP (2.3)", Time: "1h" },
    { Reason: "Celowe przedluzanie rundy (2.6)", Time: "0" },
    { Reason: "Sojusz z wrogą klasa (3.1)", Time: "0" },
    { Reason: "Sojusz z wroga klasa (3.1)", Time: "1d" },
    { Reason: "Zabicie skutego gracza (3.1)", Time: "1d" },
    { Reason: "Zabicie skutego gracza (3.1)", Time: "3d" },
    { Reason: "Utrudnianie rozgrywki sojusznikom (2.4)", Time: "1h" },
    { Reason: "Utrudnianie rozgrywki sojusznikom (2.4)", Time: "8h" },
    { Reason: "Brak wspolpracy z sojusznikami (2.4)", Time: "1d" },
    { Reason: "Reklama bez zgody zarzadu serwera (4.1)", Time: "1d" },
    { Reason: "Reklama bez zgody zarzadu serwera (4.1)", Time: "30d" },
    { Reason: "Ujawnianie moderacji z ukryta odznaka (4.3)", Time: "1h" },
    { Reason: "Proszenie moderacji o role lub przedmioty (4.2)", Time: "1h" },
    { Reason: "Dzialanie na szkode serwera i/lub graczy (4.5)", Time: "7d" },
    { Reason: "Dzialanie na szkode serwera i/lub graczy (4.5)", Time: "30d" },
    { Reason: "Dzialanie na szkode serwera i/lub graczy (4.5)", Time: "50y" },
    { Reason: "Prowokacje i naklanianie do lamania regulaminu (4.7)", Time: "1d" },
];

export default PredefinedReasonData;