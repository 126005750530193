import { useEffect, useState } from "react";
import { GetPlayerRolesForGroup } from "../../../../Scripts/Requests/PlayerInGameRolesRequest";
import AddEditPlayerInGameRole from "./Elements/AddEditPlayerInGameRole";
import PlayerInGameRolePanel from "./Elements/PlayerInGameRolePanel";
import { FaPlus } from "react-icons/fa";
import { GetRolesForGroup } from "../../../../Scripts/Requests/InGameRolesRequests";
import InGameRoleList from "../Roles/InGameRoleList";
import { GetPlayersSteamData } from "../../../../Scripts/Requests/SteamRequest";

const PlayerInGameRoles = ({groupId}) => {
    const [playerRoles, setPlayerRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [steamData, setSteamData] = useState({});

    const [showManageRoles, setShowManageRoles] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        GetRolesForGroup(groupId, setRoles);
        GetPlayerRolesForGroup(groupId, setPlayerRoles);
    }, [])

    useEffect(() => {
        if(playerRoles.length === 0)
            return;

        let steamIds = [];

        for(let playerRole of playerRoles) 
        {
            steamIds.push(playerRole.userId);
        }

        GetPlayersSteamData(steamIds, setSteamData);
    }, [playerRoles])

    const Refresh = () => {
        GetRolesForGroup(groupId, setRoles);
        GetPlayerRolesForGroup(groupId, setPlayerRoles);
    }

    const SortPlayerRoles = (a, b) => 
    {
        return a.id - b.id;
    }

    if(steamData.response == null)
        return (<></>)

    return (
        <>
            <div style={{display:"flex", flexDirection:"row-reverse", margin: "0px 15px"}}>
                <button onClick={() => setShowManageRoles(true)}>Manage Roles</button>
            </div>
            <div className="widgetListContainer" style={{maxHeight: "75%"}}>
                { playerRoles.sort((a, b) => SortPlayerRoles(a, b)).map((playerRole) => 
                    <PlayerInGameRolePanel steamData={steamData.response.players.find(x => x.steamid == playerRole.userId)} onSuccess={() => { Refresh(); }} groupId={groupId} playerRole={playerRole} roles={roles}  />
                )}

                <button onClick={() => setShowAddModal(true)} className="widgetListMainButton">
                    Add Role <FaPlus style={{marginLeft: "0.25vw"}}/>
                </button>
            </div>
 
            { showAddModal &&
                <AddEditPlayerInGameRole groupId={groupId} edit={false} closeFunction={() => setShowAddModal(false)} onSuccess={() => { Refresh(); setShowAddModal(false);}}/>
            }
            { showManageRoles &&
                <InGameRoleList closeFunction={() => setShowManageRoles(false)} groupId={groupId}/>
            }
        </>
    );
}

export default PlayerInGameRoles;