import { useEffect, useState } from "react";
import GroupButtons from "../Elements/GroupButtons/GroupButtons";
import { useParams } from "react-router-dom";
import { GetServerGroup } from "../../Scripts/Requests/ServerGroupsRequests";

import ServerGroupServerList from "./Categories/Servers/ServerGroupServerList";
import ServerGroupUserList from "./Categories/Users/ServerGroupUserList";
import ServerGroupRolesList from "./Categories/Roles/InGameRoleList";
import PlayerInGameRoles from "./Categories/PlayerRoles/PlayerInGameRoles";

const ServerGroup = () => {

    const { id } = useParams();
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [serverGroup, setServerGroup] = useState(null);
    
    useEffect(() => {
        GetServerGroup(setServerGroup, id);
    }, []);

    return (
        <>
            <div style={{margin:"20px 10%"}}>
                <GroupButtons changeValue={setSelectedCategory} selected={selectedCategory} buttons={[{text: "Users"}, {text: "Servers"}, {text: "In-Game Roles"}]}/>
            </div>
            { selectedCategory == 0 && 
                <ServerGroupUserList groupId={id}/>
            }
            { selectedCategory == 1 &&
                <ServerGroupServerList groupId={id} ServerGroup={serverGroup}/>
            }
            { selectedCategory == 2 &&
                <PlayerInGameRoles groupId={id} />
            }
        </>
    )
}

export default ServerGroup;