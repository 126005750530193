import { useEffect, useState } from "react";
import AddEditPlayerInGameRole from "./AddEditPlayerInGameRole";
import RemovePlayerRoleModal from "./RemovePlayerRoleModal";
import { GetSteamData } from "../../../../../Scripts/Requests/UsersRequests";
import { GetPlayerSteamData } from "../../../../../Scripts/Requests/SteamRequest";
import { FaSteam } from "react-icons/fa";

const PlayerInGameRolePanel = ({playerRole, groupId, roles, onSuccess, steamData}) => {

    const [editModal, setEditModal] = useState(false)
    const [removeModal, setRemoveModal] = useState(false);

    //const [steamData, setSteamData] = useState({});

    const onRemove = () => {
        onSuccess();
        setRemoveModal(false);
    }

    const onEdit = () => {
        onSuccess();
        setEditModal(false);
    }

    let username = "error";

    console.log(steamData)

    if(steamData != null) {
        username = steamData.personaname;
    }

    if(!roles)
        return (<>Error</>);

    let role = roles.find(role => role.id === playerRole.inGameRoleId);

    if(!role)
        return (<>Error</>);

    return (
        <div className="listWidget" style={{padding:"10px"}}>
            <div style={{display: "flex"}}>
                <div>
                     <img className="steamProfileImage" style={{height:"125px", width: "125px"}} src={steamData.avatarfull}  />
                    
                    <div style={{display: "flex"}}>
                        <p  style={{margin: "0px", textAlign:"center", minWidth:"100px"}}>{username}</p>
                        <a style={{marginLeft:"5px"}} href={steamData.profileurl}>
                            <FaSteam color="white" size={"23px"}/>
                        </a>
                    </div>

                </div>

                <div style={{marginLeft: "10px"}}>
                    <p>User Id: {playerRole.userId}</p>
                    <p>Discord Id: {playerRole.discordId}</p>
                </div>

                <div style={{marginLeft: "50px"}}>
                    { playerRole.shouldExpire &&
                        <p>Expires: {playerRole.expires.split("T")[0]}</p>
                    }
                    <p>Role: {role.roleName}</p>
                    { !playerRole.shouldExpire &&
                        <p>Expires: Never</p>
                    }
                </div>
            </div>
            <div style={{display: "flex"}}>
                <button style={{width:"50%"}} onClick={() => setEditModal(true)}>Edit</button>
                <button style={{width:"50%"}}  onClick={() => setRemoveModal(true)}>Remove</button>
            </div>
            { editModal &&
                <AddEditPlayerInGameRole defaultData={playerRole} groupId={groupId} edit={true} closeFunction={onEdit} onSuccess={onSuccess}/>
            }
            { removeModal &&
                <RemovePlayerRoleModal role={playerRole} closeFunction={() => setRemoveModal(false)} onSuccess={onRemove}/>
            }
        </div>
    )
}

export default PlayerInGameRolePanel;