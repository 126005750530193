import "./RequestData.css"
import playerRoles from "../../../../Data/PlayerRoles.js";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { FaCopy } from "react-icons/fa";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import { GetPlayerIP } from "../../../../Scripts/Requests/PlayersRequests.js";
import { useParams } from "react-router-dom";

function RequestData({playerData})
{
    //Server Id
    const { id } = useParams();

    const [iphidden, setIPHidden] = useState(true);
    const [playerIP, setPlayerIP] = useState("");

    useEffect(() => {
        if(playerData != null)
            GetPlayerIP(id, playerData.inGameId, setPlayerIP);
    }, []);

    if(playerData == null)
        return (                
        <div className="optionsPaneldescription">
            Select a player from the list and choose the appropriate button to retrieve data.
        </div>)

    let playerRole = playerRoles[playerData.playerRoleId + 1];

    if(!playerRole){
      playerRole = { name: "Unknown role - error" };
    }


    return(
        <div className="optionsPanelContainer requestDataMain">
                <div className="optionsPaneldescription">
                    Select a player from the list and choose the appropriate button to retrieve data.
                </div>
            { playerData != null &&
                <>                    
                    <div className="qrCodeContainer">
                        <div className="qrCodePlaceholder">
                            <QRCode value={"User Id: " + playerData.userId} size={"15vh"}/>
                        </div>
                    </div>
                    
                    <div className="requestBtnContainer">      
                        <button>Request AUTH</button>
                        <button>External lookup</button>
                    </div>

                    <table>
                        <tr>
                            <th className="infoCell">Nickname</th>
                            <th className="dataCell">{playerData.nickname}</th>
                        </tr>
                        <tr>
                            <th className="infoCell">Player ID</th>
                            <th className="dataCell">{playerData.inGameId}</th>
                        </tr>
                        <tr>
                            <th className="infoCell">IP Address:</th>
                            <th className="dataCell">
                                <div className="playerIP">
                                    { iphidden &&
                                        "****************"
                                    }
                                    { !iphidden &&
                                        <>{playerIP}</>
                                    }

                                    <IoEye onClick={() => { setIPHidden(!iphidden) }} className="iconButton" style={{marginLeft: "0.75vw"}} size={"2.5vh"}/>
                                    <FaCopy onClick={() => {navigator.clipboard.writeText(playerIP)}} className="iconButton" style={{marginLeft: "0.5vw"}} size={"2.5vh"}/>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="infoCell">Steam ID</th>
                            <th className="dataCell">
                                <div className="steamID">
                                    {playerData.userId}
                                    <FaCopy onClick={() => {navigator.clipboard.writeText(playerData.userId)}} className="iconButton" style={{marginLeft: "0.5vw"}} size={"2.5vh"} />
                                </div>   
                            </th>
                        </tr>
                        {
                            playerData.rankName != "None" &&
                            <tr>
                                <th className="infoCell">Server role</th>
                                <th className="dataCell" style={{color: playerData.rankColor}}>{playerData.rankName}</th>
                            </tr>
                        }    
                        {/* <tr>
                            <th className="infoCell">Studio status</th>
                            <th className="dataCell">playerDataStudioStatus</th>
                        </tr>
                        <tr>
                            <th className="infoCell">Active flag(s)</th>
                            <th className="dataCell">playerDataActiveFlags</th>
                        </tr> */}
                        <tr>
                            <th className="infoCell">Class</th>
                            <th className="dataCell" style={{color: playerRole.color}}>{playerRole.name}</th>
                        </tr>
                        {/* <tr>
                            <th className="infoCell">Position</th>
                            <th className="dataCell">playerDataPosition</th>
                        </tr> */}
                    </table>
                </>
            }
           
        </div>
        
    );
}

export default RequestData;