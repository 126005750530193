import { RemovePlayerRole } from "../../../../../Scripts/Requests/PlayerInGameRolesRequest";
import ModalWindow from "../../../../Elements/ModalWindow/ModalWindow";

const RemovePlayerRoleModal = ({role, closeFunction, onSuccess}) => {
    const RemoveRole = () => {
        RemovePlayerRole(role.id, onSuccess)
    }

    return (
        <ModalWindow 
            title={ "Remove" }
            showCloseButton={true}
            closeFunction={closeFunction}
            width={"45%"}
            content={
            <>
                <p>Do you really want to remove this role?</p>
                <button onClick={() => RemoveRole()} style={{margin:"20px 5px 20px", width:"calc(100% - 10px)"}}>Remove</button>
                </>}
            />
    );
}

export default RemovePlayerRoleModal;