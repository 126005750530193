import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("PlayerInGameRole/");

const GetAllPlayerRoles = (setRoles) => {
    defaultAxios.get("")
    .then((res) => 
        setRoles(res.data)
    );
}

const GetPlayerRolesForGroup = (groupId, setRoles) => {
    defaultAxios.get(`/GetForGroup/${groupId}`)
    .then((res) => 
        setRoles(res.data)
    );
}

const AddPlayerRole = (newRole, onSuccess) => {
    defaultAxios.post("", newRole)
    .then(() => onSuccess())
}

const EditPlayerRole = (role, onSuccess) => {
    defaultAxios.patch("", role)
    .then(() => onSuccess())
}

const RemovePlayerRole = (roleId, onSuccess) => {
    defaultAxios.delete(`${roleId}`)
    .then(() => onSuccess())
}

export { GetAllPlayerRoles, GetPlayerRolesForGroup, AddPlayerRole, EditPlayerRole, RemovePlayerRole };