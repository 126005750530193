import { FaPlus } from "react-icons/fa";
import InGameRolePanel from "./Elements/InGameRolePanel";
import "./InGameRoleList.css"
import { useEffect, useState } from "react";
import { GetRolesForGroup } from "../../../../Scripts/Requests/InGameRolesRequests";
import AddEditGroupRoleModal from "./Elements/AddEditGroupRoleModal";
import ModalWindow from "../../../Elements/ModalWindow/ModalWindow";

const InGameRoleList = ({groupId, closeFunction}) => {
    const [roles, setRoles] = useState([]);

    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        GetRolesForGroup(groupId, setRoles);
    }, [])

    return (
    <>
        <ModalWindow 
            title={"Roles"}
            width={"80%"}
            showCloseButton={true}
            closeFunction={closeFunction}
            content={
                <>
                    <div className="widgetListContainer" style={{maxHeight: "620px"}}>
                        { roles.map((role) => 
                            <InGameRolePanel onSuccess={() => GetRolesForGroup(groupId, setRoles)} role={role} />
                        )}

                        <button onClick={() => setShowAddModal(true)} className="widgetListMainButton">
                            Add Role <FaPlus style={{marginLeft: "0.25vw"}}/>
                        </button>
                    </div>

                    { showAddModal &&
                        <AddEditGroupRoleModal edit={false} closeFunction={() => setShowAddModal(false)} groupId={groupId} onSuccess={() => {GetRolesForGroup(groupId, setRoles); setShowAddModal(false);}}/>
                    }               
                </>
            }
        /> 
    </>
    )
}

export default InGameRoleList;