import { useState } from "react";
import RemoveInGameRoleModal from "./RemoveInGameRoleModal";
import AddEditGroupRoleModal from "./AddEditGroupRoleModal";

const InGameRolePanel = ({role, onSuccess}) => {
    const [removeWindow, setRemoveWindow] = useState(false);
    const [editWindow, setEditWindow] = useState(false);

    const onDeleted = () => {
        setRemoveWindow(false);
        onSuccess();
    };

    const onEdit = () => {
        setEditWindow(false);
        onSuccess();
    }

    return (
        <>
            <div className="listWidget" style={{padding:"5px"}}>
                <div style={{display: "flex"}}>
                    <div style={{width: "50%", display: "flex"}}>
                        <p><strong>{role.roleName}</strong></p>
                        <p style={{marginLeft:"5px"}}>Priority: {role.priority}</p>
                    </div>
                    <div style={{width: "50%", display: "flex", flexDirection: "row-reverse"}}>
                        <button onClick={() => setEditWindow(true)} style={{width: "25%"}}>Edit</button>
                        <button onClick={() => setRemoveWindow(true)} style={{width: "25%"}}>Remove</button>
                    </div>
                </div>
            </div>     
            { editWindow &&
                <AddEditGroupRoleModal onSuccess={() => onEdit()} defaultData={role} edit={true} closeFunction={() => setEditWindow(false)} />
            }
            { removeWindow &&
                <RemoveInGameRoleModal onSuccess={() => onDeleted()} closeFunction={() => setRemoveWindow(false)} role={role}/>
            }   
        </>
    )
}

export default InGameRolePanel;