import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("Steam/");

const GetPlayersSteamData = (steamIds, SetSteamIds) => {

    let url = "";
    for(let steamId of steamIds) {
        url += `${steamId};`; 
    }

    defaultAxios.get(url)
    .then((res) => 
    {
        SetSteamIds(res.data);
    })
}

const GetPlayerSteamData = (steamIds, SetSteamId) => {

    let url = `${steamIds}`;

    defaultAxios.get(url)
    .then((res) => 
    {
        SetSteamId(res.data);
    })
}

export { GetPlayersSteamData, GetPlayerSteamData };