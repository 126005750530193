import { useState } from "react";
import { ExecuteCommand } from "../../../../Scripts/Requests/ServerRequests";

const RaConsole = ({serverId}) => {

    const [command, setCommand] = useState("");

    const SendCommand = () => {
        let commandToExecute = `/${command}`;

        ExecuteCommand(serverId, commandToExecute);
    }

    return(
        <>
            <div className="optionsPanelContainer">
                <div className="darkerMessageContainer" style={{height:"70vh"}}>
                    
                </div>

                <div style={{display: "flex"}}>
                    <div className="txtAreaContainer" style={{width: "90%", height: "3rem"}}>
                        <textarea value={command} onChange={(e) => setCommand(e.target.value)} className="txtArea" style={{width: "100%", height: "3rem"}} placeholder="Command..."></textarea>
                    </div>
                    
                    <button onClick={() => SendCommand()} style={{width: "10%", height: "3rem"}}>Send</button>
                </div>

            </div>

        </>
    );
}
export default RaConsole;