import { getDefaultAxios } from "./Request";

const defaultAxios = getDefaultAxios("InGameRoles/");

const GetAllRoles = (setRoles) => {
    defaultAxios.get("")
    .then((res) => 
        setRoles(res.data)
    );
}

const GetRolesForGroup = (groupId, setRoles) => {
    defaultAxios.get(`/GetForGroup/${groupId}`)
    .then((res) => 
        setRoles(res.data)
    );
}

const AddRole = (newRole, onSuccess) => {
    defaultAxios.post("", newRole)
    .then(() => onSuccess())
}

const EditRole = (role, onSuccess) => {
    defaultAxios.patch("", role)
    .then(() => onSuccess())
}

const RemoveRole = (roleId, onSuccess) => {
    defaultAxios.delete(`${roleId}`)
    .then(() => onSuccess())
}

export { GetAllRoles, GetRolesForGroup, AddRole, EditRole, RemoveRole };